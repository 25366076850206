import {BlockedTime} from '@Common/api/blockedTime';
import {PlannedTime} from '@Common/api/plannedTime';
import {useDroppable} from '@dnd-kit/core';
import {format, getDay, isSameDay} from 'date-fns';
import {memo, useMemo} from 'react';
import {makeStyles} from 'tss-react/mui';

import BlockedDayContent from './BlockedDayContent';
import SingleDayContent from './SingleDayContent';

interface Props {
	plannedTimes: PlannedTime[];
	blockedTimes: BlockedTime[];
	day: Date;
	userId: string;
	fullHeight: number;
	width: number;
	onChange: (newPlannedTime: PlannedTime) => void;
	onDelete: (plannedTime: PlannedTime, future?: boolean) => void;
	onNewDays?: (
		plannedTime: PlannedTime,
		numberOfDays: number
	) => Promise<void>;
	compact?: boolean;
	isOver?: boolean;
}

const SingleDay = (props: Props) => {
	const {classes} = useStyles();

	const filterPlannedTimes = (
		plannedTimes: PlannedTime[],
		userId: string,
		day: Date
	): PlannedTime[] => {
		return plannedTimes.filter(
			(p) => p.userId === userId && isSameDay(p.day, day)
		);
	};

	const plannedTimes = useMemo(
		() => filterPlannedTimes(props.plannedTimes, props.userId, props.day),
		[props.plannedTimes, props.userId, props.day]
	);

	const blockedTime = useMemo(() => {
		return props.blockedTimes.find(
			(bt) => bt.userId === props.userId && isSameDay(bt.day, props.day)
		);
	}, [props.blockedTimes, props.userId, props.day]);

	const isWeekend = getDay(props.day) === 0 || getDay(props.day) === 6;
	const isCurrentDay = isSameDay(props.day, new Date());

	return (
		<div
			className={`${classes.dayContainer} ${
				isWeekend ? classes.weekend : ''
			} ${isCurrentDay ? classes.current : ''} ${
				blockedTime ? classes.blocked : ''
			} ${blockedTime?.readonly ? classes.blockedReadOnly : ''} ${
				props.isOver ? classes.over : ''
			}`}
			style={{width: props.width}}
		>
			{plannedTimes.map((pt) => (
				<SingleDayContent
					plannedTime={pt}
					key={pt._id}
					onChange={props.onChange}
					onDelete={props.onDelete}
					onNewDays={props.onNewDays}
					fullHeight={props.fullHeight}
					width={props.width}
					compact={props.compact}
				/>
			))}
			<BlockedDayContent
				blockedTime={blockedTime}
				userId={props.userId}
				date={props.day}
			/>
		</div>
	);
};

const useStyles = makeStyles()((theme) => ({
	dayContainer: {
		flexShrink: 0,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.palette.grey[200],
		borderBottomColor: theme.palette.grey[400],
		borderTopColor: theme.palette.grey[400],
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	weekend: {
		backgroundColor: theme.palette.grey[300],
	},
	current: {
		backgroundColor: theme.palette.secondary.light,
	},
	blocked: {
		backgroundColor: theme.palette.error.light,
	},
	blockedReadOnly: {
		backgroundColor: theme.palette.error.main,
	},
	over: {
		backgroundColor: theme.palette.secondary.dark,
	},
}));

const SingleDayMemo = memo(SingleDay);

const SingleDayWrapper = (props: Props) => {
	const {setNodeRef, isOver} = useDroppable({
		id: `${format(props.day, 'yyyy-MM-dd')}-${props.userId}`,
		data: {
			day: props.day,
			userId: props.userId,
		},
	});

	return (
		<div ref={setNodeRef}>
			<SingleDayMemo {...props} isOver={isOver} />
		</div>
	);
};

export default memo(SingleDayWrapper);
