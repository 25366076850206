import {useQueryClient, useMutation} from '@tanstack/react-query';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import {
	createContext,
	useContext,
	useMemo,
	useState,
	useCallback,
	useEffect,
} from 'react';

interface JWTPayload {
	exp: number;
	perm: string[];
}

interface UseAuth {
	token: string | null;
	login: any;
	logout: () => void;
}

const AuthContext = createContext<UseAuth>({
	token: null,
	login: () => {},
	logout: () => {},
});

export const AuthProvider = ({children}: any) => {
	const queryClient = useQueryClient();
	const [token, setToken] = useState<string | null>(null);
	const [initialized, setInitialized] = useState(false);

	const logout = useCallback(() => {
		setToken(null);
		queryClient.cancelQueries();
		queryClient.invalidateQueries();

		window.localStorage.removeItem('jwt');
		axios.defaults.headers.common['Authorization'] = '';
	}, [queryClient]);

	const loginWithToken = useCallback(
		async (token: string) => {
			// validate token
			const decoded: JWTPayload = jwtDecode(token);

			// Make sure token is still valid
			if (decoded.exp < Date.now() / 1000) {
				logout();
				return;
			}

			// save the token
			window.localStorage.setItem('jwt', token);

			// set default authorization header for all future requests
			axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

			setToken(token);
		},
		[logout]
	);

	// When loading application check if we are logged in
	useEffect(() => {
		// If we are already initialized we don't need to do anything
		if (initialized) {
			return;
		}

		// add interception function to logout if we receive a 401 respone
		axios.interceptors.response.use(
			// for sucessful respones we simple return the respone
			(respone) => respone,
			// for errors check the status code, 401 means unauthorized so log the user out
			(error) => {
				if (
					error.response?.status === 401 &&
					error.config?.url !== '/users/login'
				) {
					// If we are logging in, we don't care about logging out
					console.log('Received 401 response, logging user out');
					logout();
				} else {
					return Promise.reject(error);
				}
			}
		);

		const asyncFunction = async () => {
			const token = window.localStorage.getItem('jwt');

			if (token) {
				await loginWithToken(token);
			} else {
				logout();
			}

			// Mark as initialized, this causes the rest of the App to render
			setInitialized(true);
		};
		asyncFunction();
	}, [initialized, loginWithToken, logout]);

	const login = useMutation({
		mutationFn: async (variables: {
			email: string;
			password: string;
		}): Promise<any> => {
			try {
				const result = await axios.post('/users/login', {
					email: variables.email,
					password: variables.password,
				});

				if (
					result?.data &&
					result.data.success === true &&
					result.data.token
				) {
					await loginWithToken(result.data.token);
					return result;
				} else {
					throw new Error('Invalid Response');
				}
			} catch (error) {
				if ((error as any).response?.data?.message) {
					throw new Error((error as any).response?.data?.message);
				} else {
					throw error;
				}
			}
		},
	});

	const value = useMemo(
		() => ({
			token,
			login,
			logout,
		}),
		[token, login, logout]
	);

	return (
		<AuthContext.Provider value={value}>
			{initialized && children}
		</AuthContext.Provider>
	);
};

export function useAuth(): UseAuth {
	return useContext(AuthContext);
}
