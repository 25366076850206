import {PlannedTime} from '@Common/api/plannedTime';
import {useDraggable} from '@Common/Draggable';
import TicketTooltip from '@Common/TicketTooltip';
import {Typography} from '@mui/material';
import {memo, useCallback, useState} from 'react';
import {ResizableBox} from 'react-resizable';
import {makeStyles} from 'tss-react/mui';

interface Props {
	plannedTime: PlannedTime;
	fullHeight: number;
	width: number;
	onChange?: (newPlannedTime: PlannedTime) => void;
	isInDragOverlay?: boolean;
}

const margin = 6;

function PlannedTimeView(props: Props) {
	const {classes} = useStyles();
	const {enableDrag, disableDrag, isDragging} = useDraggable();

	const [duration, setDuration] = useState(props.plannedTime.duration);

	const minHeight = (0.5 / 8) * props.fullHeight;
	const calcHeight =
		(Math.max(0.5, props.plannedTime.duration) / 8) * props.fullHeight;

	const {onChange, plannedTime} = props;
	const onResize = useCallback(
		(newDuration: number) => {
			const newPlannedTime = new PlannedTime({
				...plannedTime,
				duration: newDuration,
			});
			onChange?.(newPlannedTime);
		},
		[onChange, plannedTime]
	);

	const formattedDuration = duration.toLocaleString('de-DE', {
		maximumFractionDigits: 1,
	});
	const realWidth = props.width - margin;

	return (
		<ResizableBox
			className={classes.ticketContainer}
			height={calcHeight}
			width={realWidth}
			draggableOpts={{
				grid: [realWidth, minHeight],
				disabled: props.plannedTime.readonly === true,
			}}
			maxConstraints={[realWidth, props.fullHeight]}
			minConstraints={[realWidth, minHeight]}
			resizeHandles={['s']}
			axis='y'
			handle={(handleAxis, ref) =>
				props.plannedTime.readonly ? (
					<span />
				) : (
					<span
						className={`react-resizable-handle react-resizable-handle-${handleAxis}`}
						ref={ref}
						onMouseEnter={() => disableDrag()}
						onMouseLeave={() => enableDrag()}
					/>
				)
			}
			onResizeStop={(_e, {size}) =>
				onResize((size.height / props.fullHeight) * 8)
			}
			onResize={(_e, {size}) =>
				setDuration((size.height / props.fullHeight) * 8)
			}
			style={{
				backgroundColor: props.plannedTime.ticket.project_color,
			}}
		>
			<TicketTooltip
				ticket={props.plannedTime.ticket}
				isDragging={isDragging || props.isInDragOverlay === true}
			>
				<div className={classes.innerContainer}>
					<div className={classes.topRow}>
						<Typography variant='subtitle2' component='div'>
							#{props.plannedTime.ticket.id}
						</Typography>
						<Typography variant='body2' component='div'>
							{formattedDuration}h
						</Typography>
					</div>
					<Typography
						variant='body2'
						component='div'
						className={classes.ticketName}
					>
						{props.plannedTime.ticket.subject}
					</Typography>
					<Typography variant='caption' component='div'>
						{props.plannedTime.ticket.project_name}
					</Typography>
				</div>
			</TicketTooltip>
		</ResizableBox>
	);
}

const useStyles = makeStyles()((theme) => ({
	ticketContainer: {
		overflow: 'hidden',
		borderRadius: 3,
		margin: 2,
		boxSizing: 'border-box',
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: 'rgba(0,0,0, 0.2)',
	},
	innerContainer: {
		display: 'flex',
		flexDirection: 'column',
		padding: 2,
		paddingTop: 0,
		paddingBottom: 4,
		height: '100%',
	},
	topRow: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	ticketName: {
		flex: 1,
	},
}));

export default memo(PlannedTimeView);
