import {fetchGenerics} from './generic';

export class User {
	id: string = '';
	email: string = '';
	firstname: string = '';
	lastname: string = '';

	constructor(jsonData: any = {}) {
		Object.assign(this, jsonData);
	}
}

export async function fetchUsers() {
	const response = await fetchGenerics<User>('/users/', User);
	return response;
}
