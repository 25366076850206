import {PlannedTime} from '@Common/api/plannedTime';
import Draggable from '@Common/Draggable';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import {useCallback, useState} from 'react';

import CompactPlannedTimeView from './CompactPlannedTimeView';
import PlannedTimeView from './PlannedTimeView';

interface Props {
	plannedTime: PlannedTime;
	fullHeight: number;
	width: number;
	onChange: (newPlannedTime: PlannedTime) => void;
	onDelete: (plannedTime: PlannedTime, future?: boolean) => void;
	onNewDays?: (
		plannedTime: PlannedTime,
		numberOfDays: number
	) => Promise<void>;
	compact?: boolean;
}

export default function SingleDayContent(props: Props) {
	const [contextMenu, setContextMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	const handleContextMenu = (event: React.MouseEvent) => {
		event.preventDefault();

		if (props.plannedTime.readonly) {
			return;
		}

		setContextMenu(
			contextMenu === null
				? {
						mouseX: event.clientX + 2,
						mouseY: event.clientY - 6,
					}
				: // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
					// Other native context menus might behave different.
					// With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
					null
		);
	};

	const handleClose = useCallback(() => {
		setContextMenu(null);
	}, []);

	const {plannedTime, onDelete} = props;
	const handleOpenInNew = useCallback(() => {
		window.open(
			`https://office.elephantsweb.at/work_packages/${plannedTime.ticketNumber}/activity`,
			'_blank'
		);
		handleClose();
	}, [handleClose, plannedTime.ticketNumber]);

	const handleDelete = useCallback(() => {
		onDelete(plannedTime);
		handleClose();
	}, [onDelete, plannedTime, handleClose]);

	const handleDeleteFuture = useCallback(() => {
		onDelete(plannedTime, true);
		handleClose();
	}, [onDelete, plannedTime, handleClose]);

	return (
		<div onContextMenu={handleContextMenu}>
			<Draggable
				id={props.plannedTime._id}
				type='plannedTime'
				plannedTime={props.plannedTime}
				dragAlwaysDisabled={props.plannedTime.readonly}
			>
				{props.compact === true && (
					<CompactPlannedTimeView
						plannedTime={props.plannedTime}
						fullHeight={props.fullHeight}
						width={props.width}
						onNewDays={props.onNewDays}
					/>
				)}
				{props.compact !== true && (
					<PlannedTimeView
						plannedTime={props.plannedTime}
						fullHeight={props.fullHeight}
						width={props.width}
						onChange={props.onChange}
					/>
				)}
			</Draggable>
			<Menu
				open={contextMenu !== null}
				onClose={handleClose}
				anchorReference='anchorPosition'
				anchorPosition={
					contextMenu !== null
						? {
								top: contextMenu.mouseY,
								left: contextMenu.mouseX,
							}
						: undefined
				}
			>
				<MenuItem onClick={handleOpenInNew}>
					<ListItemIcon>
						<OpenInNewIcon fontSize='small' />
					</ListItemIcon>
					<ListItemText>
						#{props.plannedTime.ticketNumber} Öffnen
					</ListItemText>
				</MenuItem>
				<MenuItem onClick={handleDelete}>
					<ListItemIcon>
						<DeleteOutlineIcon fontSize='small' />
					</ListItemIcon>
					<ListItemText>
						#{props.plannedTime.ticketNumber} Löschen
					</ListItemText>
				</MenuItem>
				<MenuItem onClick={handleDeleteFuture}>
					<ListItemIcon>
						<DeleteSweepIcon fontSize='small' />
					</ListItemIcon>
					<ListItemText>
						Zukünftige #{props.plannedTime.ticketNumber} Löschen
					</ListItemText>
				</MenuItem>
			</Menu>
		</div>
	);
}
