import {fetchProjects} from '@Common/api/projects';
import {fetchProjectsOverview} from '@Common/api/projectsOverview';
import MenuIcon from '@mui/icons-material/Menu';
import {
	AppBar,
	Drawer,
	IconButton,
	Paper,
	Toolbar,
	Typography,
} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import {useQuery} from '@tanstack/react-query';
import {useCallback, useState} from 'react';
import {makeStyles} from 'tss-react/mui';

import SingleProject from './SingleProject';
import AccountIcon from '../AccountIcon';
import NavigationDrawer from '../NavigationDrawer';

export default function ProjectsOverviewScreen() {
	const {classes} = useStyles();

	const [navDrawerOpen, setNavDrawerOpen] = useState(false);
	const [reportDate, setReportDate] = useState<Date>(new Date());

	const toggleNavDrawer = useCallback(() => {
		setNavDrawerOpen((open) => !open);
	}, []);

	const projects = useQuery({
		queryKey: ['projects'],
		queryFn: () => fetchProjects(),
	});

	const projectsOverviews = useQuery({
		queryKey: ['projects-overviews', reportDate],
		queryFn: () => fetchProjectsOverview(reportDate),
	});

	const handleReportDateChange = useCallback((date: Date | null) => {
		setReportDate(date ?? new Date());
	}, []);

	return (
		<>
			<AppBar position='sticky' className={classes.appBar}>
				<Toolbar>
					<IconButton onClick={toggleNavDrawer} color='inherit'>
						<MenuIcon />
					</IconButton>
					<Typography variant='h6'>
						ELEPHANTS 5 Team Planner
					</Typography>
					<div className={classes.spacer} />
					<AccountIcon />
				</Toolbar>
			</AppBar>
			<Drawer variant='persistent' anchor='left' open={navDrawerOpen}>
				<NavigationDrawer />
			</Drawer>
			<Paper className={classes.paper}>
				<Typography variant='h3' mb={2}>
					Projektübersicht
				</Typography>
				<DatePicker
					label='Stichtag (inklusive)'
					value={reportDate}
					onChange={handleReportDateChange}
				/>
				{projectsOverviews.data &&
					projects.data?.map((child) => (
						<SingleProject
							project={child}
							projectsOverview={projectsOverviews.data ?? []}
							key={child.id}
						/>
					))}
			</Paper>
		</>
	);
}

const useStyles = makeStyles()((theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	spacer: {
		flexGrow: 1,
	},
	paper: {
		margin: theme.spacing(2),
		padding: theme.spacing(1),
	},
}));
