import App from '@App/App';
import {AuthProvider} from '@Common/Authentication';
import {muiTheme} from '@Common/Theme';
import {EEnvironment, versionData} from '@Common/versionData';
import {CssBaseline} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import axios from 'axios';
import {de} from 'date-fns/locale/de';
import {SnackbarProvider} from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';

import 'react-resizable/css/styles.css';

// Setup Axios Defaults
switch (versionData.environment) {
	case EEnvironment.PRODUCTION:
		axios.defaults.baseURL = '/api/';
		break;
	default:
		axios.defaults.baseURL = 'http://localhost:3001/api/';
}

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: true,
			refetchOnMount: true,
			refetchOnReconnect: true,
			retry: false,
		},
	},
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<CssBaseline />
		<ThemeProvider theme={muiTheme}>
			<LocalizationProvider
				dateAdapter={AdapterDateFns}
				adapterLocale={de}
			>
				<SnackbarProvider maxSnack={3}>
					<QueryClientProvider client={queryClient}>
						<AuthProvider>
							<App />
						</AuthProvider>
					</QueryClientProvider>
				</SnackbarProvider>
			</LocalizationProvider>
		</ThemeProvider>
	</React.StrictMode>
);
