import {PlannedTime} from '@Common/api/plannedTime';
import {useDraggable} from '@Common/Draggable';
import TicketTooltip from '@Common/TicketTooltip';
import {Typography} from '@mui/material';
import {useCallback, useState} from 'react';
import {ResizableBox} from 'react-resizable';
import {makeStyles} from 'tss-react/mui';

interface Props {
	plannedTime: PlannedTime;
	fullHeight: number;
	width: number;
	onNewDays?: (
		plannedTime: PlannedTime,
		numberOfDays: number
	) => Promise<void>;
	isInDragOverlay?: boolean;
}

const margin = 6;

export default function CompactPlannedTimeView(props: Props) {
	const {classes} = useStyles();
	const {enableDrag, disableDrag, isDragging} = useDraggable();
	const [key, setKey] = useState(1);

	const calcHeight =
		(Math.max(0.5, props.plannedTime.duration) / 8) * props.fullHeight;

	const {onNewDays, plannedTime} = props;
	const onResize = useCallback(
		async (numberOfDays: number) => {
			if (numberOfDays >= 1) {
				await onNewDays?.(plannedTime, numberOfDays);
			}
			setKey((inKey) => inKey + 1);
		},
		[onNewDays, plannedTime]
	);

	const realWidth = props.width - margin;

	return (
		<ResizableBox
			className={classes.ticketContainer}
			height={calcHeight}
			width={realWidth}
			draggableOpts={{
				grid: [props.width, calcHeight],
				disabled: props.plannedTime.readonly === true,
			}}
			minConstraints={[realWidth, calcHeight]}
			resizeHandles={['e']}
			axis='x'
			handle={(handleAxis, ref) =>
				props.plannedTime.readonly ? (
					<span />
				) : (
					<span
						className={`react-resizable-handle react-resizable-handle-${handleAxis}`}
						ref={ref}
						onMouseEnter={() => disableDrag()}
						onMouseLeave={() => enableDrag()}
					/>
				)
			}
			onResizeStop={(e, {size}) => {
				e.preventDefault();
				onResize((size.width + margin) / props.width - 1);
			}}
			style={{
				backgroundColor: props.plannedTime.ticket.project_color,
			}}
			key={key}
		>
			<TicketTooltip
				ticket={props.plannedTime.ticket}
				isDragging={isDragging || props.isInDragOverlay === true}
			>
				<div className={classes.innerContainer}>
					<Typography
						variant='subtitle2'
						component='div'
						className={classes.label}
					>
						#{props.plannedTime.ticket.id}
					</Typography>
				</div>
			</TicketTooltip>
		</ResizableBox>
	);
}

const useStyles = makeStyles()((theme) => ({
	ticketContainer: {
		overflow: 'hidden',
		borderRadius: 3,
		margin: 2,
		boxSizing: 'border-box',
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: 'rgba(0,0,0, 0.2)',
	},
	innerContainer: {
		display: 'flex',
		flexDirection: 'column',
		padding: 2,
		paddingTop: 0,
		paddingBottom: 4,
		paddingLeft: 0,
		justifyContent: 'center',
		height: '100%',
	},
	label: {
		transform: 'rotate(180deg)',
		textOrientation: 'sideways',
		writingMode: 'vertical-rl',
	},
}));
