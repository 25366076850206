import {useDraggable as useDnDDraggable} from '@dnd-kit/core';
import {createContext, useCallback, useContext, useMemo, useState} from 'react';

import {PlannedTime} from './api/plannedTime';
import {Ticket} from './api/tickets';

interface Props {
	children: React.ReactNode;
	id: string;
	type: 'ticket' | 'plannedTime';
	plannedTime?: PlannedTime;
	ticket?: Ticket;
	dragAlwaysDisabled?: boolean;
}

interface UseDraggable {
	enableDrag: () => void;
	disableDrag: () => void;
	isDragging: boolean;
}

const DraggableContext = createContext<UseDraggable>({
	enableDrag: () => {},
	disableDrag: () => {},
	isDragging: false,
});

export default function Draggable(props: Props) {
	const [isDraggable, setIsDraggable] = useState(true);

	const {attributes, listeners, setNodeRef, isDragging} = useDnDDraggable({
		id: props.id,
		data: {
			type: props.type,
			data: props.type === 'ticket' ? props.ticket : props.plannedTime,
		},
		disabled: isDraggable === false || props.dragAlwaysDisabled,
	});

	const enableDrag = useCallback(() => {
		setIsDraggable(true);
	}, []);

	const disableDrag = useCallback(() => {
		setIsDraggable(false);
	}, []);

	const value = useMemo(
		() => ({
			enableDrag,
			disableDrag,
			isDragging,
		}),
		[enableDrag, disableDrag, isDragging]
	);

	return (
		<DraggableContext.Provider value={value}>
			<div
				ref={setNodeRef}
				{...listeners}
				{...attributes}
				style={{
					cursor:
						props.dragAlwaysDisabled === true ? 'default' : 'grab',
				}}
			>
				{props.children}
			</div>
		</DraggableContext.Provider>
	);
}

export function useDraggable(): UseDraggable {
	return useContext(DraggableContext);
}
