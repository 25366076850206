import {useAuth} from '@Common/Authentication';
import {Navigate, Outlet} from 'react-router-dom';

function PrivateLayout() {
	const {token} = useAuth();
	if (!token) {
		return <Navigate to='/login' />;
	}

	return (
		<>
			<Outlet />
		</>
	);
}

export default PrivateLayout;
