import {colorForProject} from '@Common/colorHash';

import {fetchGenerics} from './generic';

export class Milestone {
	id: string = '';
	project_id: string = '';
	subject: string = '';
	due_date: Date = new Date();
	project_name: string = '';
	parent_project_name?: string = undefined;
	project_color: string;

	constructor(jsonData: any = {}) {
		Object.assign(this, jsonData);

		this.project_color = colorForProject(this.project_id);

		if (jsonData.due_date) {
			this.due_date = new Date(jsonData.due_date);
		}
	}
}

export async function fetchMilestones() {
	const response = await fetchGenerics<Milestone>('/milestones/', Milestone);
	return response;
}
