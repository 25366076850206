import {Modifier} from '@dnd-kit/core';
import {getEventCoordinates} from '@dnd-kit/utilities';

export const followMouseModifier: Modifier = ({
	active,
	activatorEvent,
	draggingNodeRect,
	transform,
}) => {
	if (
		active?.data.current?.type === 'ticket' &&
		draggingNodeRect &&
		activatorEvent
	) {
		const activatorCoordinates = getEventCoordinates(activatorEvent);

		if (activatorCoordinates) {
			const offsetX =
				activatorCoordinates.x -
				draggingNodeRect.left -
				draggingNodeRect.width / 2;

			const offsetY =
				activatorCoordinates.y -
				draggingNodeRect.top -
				draggingNodeRect.height / 2;

			return {
				...transform,
				x: transform.x + offsetX,
				y: transform.y + offsetY,
			};
		}
	}

	return transform;
};
