import {
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Toolbar,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {makeStyles} from 'tss-react/mui';

export default function NavigationDrawer() {
	const navigate = useNavigate();
	const {classes} = useStyles();

	return (
		<div className={classes.container}>
			<Toolbar />
			<List>
				<ListItem disablePadding>
					<ListItemButton onClick={() => navigate('/')}>
						<ListItemText primary='Kalender' />
					</ListItemButton>
				</ListItem>
				<ListItem disablePadding>
					<ListItemButton onClick={() => navigate('/report')}>
						<ListItemText primary='Auswertung' />
					</ListItemButton>
				</ListItem>
				<ListItem disablePadding>
					<ListItemButton
						onClick={() => navigate('/projects-overview')}
					>
						<ListItemText primary='Projektübersicht' />
					</ListItemButton>
				</ListItem>
			</List>
		</div>
	);
}

const useStyles = makeStyles()((theme) => ({
	container: {
		minWidth: 240,
	},
}));
