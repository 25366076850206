import {Tooltip, Typography} from '@mui/material';
import {memo, useCallback, useEffect, useState} from 'react';

import {Ticket} from './api/tickets';

interface Props {
	ticket: Ticket;
	children: React.ReactElement;
	isDragging: boolean;
}

function TicketTooltip(props: Props) {
	const [tooltipShown, setTooltipShown] = useState(false);

	const {isDragging} = props;
	useEffect(() => {
		if (isDragging === true) {
			setTooltipShown(false);
		}
	}, [isDragging]);

	const openTooltip = useCallback(() => {
		if (isDragging === false) {
			setTooltipShown(true);
		}
	}, [isDragging]);

	const closeTooltip = useCallback(() => {
		setTooltipShown(false);
	}, []);

	return (
		<Tooltip
			title={
				<>
					<Typography variant='subtitle2'>
						#{props.ticket.id}
					</Typography>
					<Typography variant='body2'>
						{props.ticket.subject}
					</Typography>
					<Typography variant='caption'>
						{props.ticket.project_name}
					</Typography>
				</>
			}
			enterDelay={300}
			open={tooltipShown}
			onOpen={openTooltip}
			onClose={closeTooltip}
		>
			{props.children}
		</Tooltip>
	);
}

export default memo(TicketTooltip);
