import {colorForProject} from '@Common/colorHash';

import {fetchGenerics} from './generic';

export class Ticket {
	id: string = '';
	project_id: string = '';
	subject: string = '';
	estimated_hours: number = 0;
	remaining_hours: number = 0;
	unplanned_hours: number = 0;
	project_name: string = '';

	project_color: string;

	constructor(jsonData: any = {}) {
		Object.assign(this, jsonData);

		this.project_color = colorForProject(this.project_id);
	}

	public formattedUnplannedHours(): string {
		return this.unplanned_hours.toLocaleString('de-DE', {
			maximumFractionDigits: 1,
		});
	}
}

export async function fetchTickets() {
	const response = await fetchGenerics<Ticket>('/tickets/', Ticket);
	return response;
}
