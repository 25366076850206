import {useAuth} from '@Common/Authentication';
import {Navigate, Outlet} from 'react-router-dom';

function PublicLayout() {
	const {token} = useAuth();

	if (token) {
		return <Navigate to='/' />;
	}

	return (
		<>
			<Outlet />
		</>
	);
}

export default PublicLayout;
