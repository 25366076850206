import preval from 'babel-plugin-preval/macro';

export enum EEnvironment {
	DEVELOPMENT = 'development',
	STAGING = 'staging',
	PRODUCTION = 'production',
}

const droneSha = preval`module.exports = process.env.DRONE_COMMIT_SHA`;
const droneBuild = preval`module.exports = process.env.DRONE_BUILD_NUMBER`;
const droneDate = preval`module.exports = process.env.DRONE_BUILD_STARTED`;
const droneBranch = preval`module.exports = process.env.DRONE_COMMIT_BRANCH`;

let environment = EEnvironment.DEVELOPMENT;
if (droneBranch === 'main') {
	environment = EEnvironment.PRODUCTION;
} else if (droneBranch === 'develop') {
	environment = EEnvironment.STAGING;
}

let versionString = 'DEVELOPMENT';
if (droneSha && droneBuild && droneDate) {
	versionString = `Build #${droneBuild} (${droneSha.slice(
		0,
		7
	)}) from ${new Date(parseInt(droneDate, 10) * 1000)})`;
}

console.log(`Running TeamPlanner ${versionString}`);

export const versionData = {
	environment,
	gitSha: droneSha ?? '',
	buildNumber: droneBuild ?? '',
	buildDate: droneDate
		? new Date(parseInt(droneDate, 10) * 1000)
		: new Date(),
	versionString,
};
