import {Milestone} from '@Common/api/milestone';
import {Tooltip, Typography} from '@mui/material';
import {useMemo} from 'react';
import {makeStyles} from 'tss-react/mui';

interface Props {
	milestone: Milestone;
}

export default function MilestoneLabel(props: Props) {
	const {classes} = useStyles();

	const textLabel = useMemo(() => {
		let project_name = props.milestone.project_name;

		if (
			props.milestone.parent_project_name &&
			props.milestone.parent_project_name !== 'Allgemein'
		) {
			project_name = `${props.milestone.parent_project_name} / ${project_name}`;
		}

		return `${props.milestone.subject} (${project_name})`;
	}, [props.milestone]);

	return (
		<Tooltip title={textLabel}>
			<div
				className={classes.milestone}
				style={{backgroundColor: props.milestone.project_color}}
			>
				<Typography variant='body2'>{textLabel}</Typography>
			</div>
		</Tooltip>
	);
}

const useStyles = makeStyles()((theme) => ({
	milestone: {
		boxSizing: 'border-box',
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: 'rgba(0,0,0, 0.2)',
		borderRadius: 3,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
}));
