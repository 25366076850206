import {Project} from '@Common/api/projects';
import {ProjectsOverview} from '@Common/api/projectsOverview';
import {Typography} from '@mui/material';
import {useMemo} from 'react';
import {makeStyles} from 'tss-react/mui';

interface SingleProjectProps {
	project: Project;
	projectsOverview: ProjectsOverview[];
}

export default function SingleProject(props: SingleProjectProps) {
	const {classes} = useStyles();

	// check if we have a matching projectOverview
	const projectOverview = useMemo(() => {
		return props.projectsOverview.find(
			(po) => po.project_id === props.project.id
		);
	}, [props.project.id, props.projectsOverview]);

	const formatDuration = (duration: number) => {
		return (
			duration.toLocaleString('de-DE', {
				maximumFractionDigits: 1,
			}) + ' h'
		);
	};

	return (
		<div
			className={classes.singleProjectContainer}
			style={{
				backgroundColor: props.project.project_color,
			}}
		>
			<Typography variant='subtitle1' component='div'>
				{props.project.name}
			</Typography>
			{projectOverview && (
				<Typography variant='body2' component='div'>
					<p>
						Gebucht: {formatDuration(projectOverview.logged_hours)}
						<br />
						Geplant: {formatDuration(projectOverview.planned_hours)}
						<br />
						<strong>
							Gesamt:{' '}
							{formatDuration(
								projectOverview.logged_hours +
									projectOverview.planned_hours
							)}
						</strong>
						<br />
						Geschätzt:{' '}
						{formatDuration(projectOverview.estimated_hours)}
						(offen:{' '}
						{formatDuration(
							projectOverview.estimated_hours -
								(projectOverview.logged_hours +
									projectOverview.planned_hours)
						)}
						)
					</p>
				</Typography>
			)}
			{props.project.children?.map((child) => (
				<SingleProject
					project={child}
					projectsOverview={props.projectsOverview}
					key={child.id}
				/>
			))}
		</div>
	);
}

const useStyles = makeStyles()((theme) => ({
	singleProjectContainer: {
		borderRadius: 3,
		margin: 2,
		padding: 4,
		paddingLeft: 8,
		boxSizing: 'border-box',
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: 'rgba(0,0,0, 0.2)',
	},
}));
