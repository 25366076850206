import {useAuth} from '@Common/Authentication';
import Theme from '@Common/Theme';
import {Button, Paper, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import {makeStyles} from 'tss-react/mui';

function Login() {
	const {classes} = useStyles();
	const {login} = useAuth();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
		event.preventDefault();
		login.mutate({email, password});
	}

	return (
		<Paper
			component='form'
			onSubmit={(event) => handleSubmit(event)}
			className={classes.container}
		>
			<Typography variant='h1'>Login</Typography>
			<TextField
				label='E-Mail'
				name='email'
				variant='outlined'
				required
				fullWidth
				color='primary'
				margin='normal'
				size='small'
				onChange={(e) => setEmail(e.target.value)}
				autoFocus
			/>
			<TextField
				label='Passwort'
				name='password'
				variant='outlined'
				required
				fullWidth
				color='primary'
				margin='normal'
				size='small'
				type='password'
				autoComplete='current-password'
				onChange={(e) => setPassword(e.target.value)}
			/>
			<Button
				variant='contained'
				type='submit'
				disabled={login.isLoading}
			>
				Anmelden
			</Button>
		</Paper>
	);
}

const useStyles = makeStyles()((theme) => ({
	container: {
		maxWidth: 640,
		alignSelf: 'center',
		padding: Theme.spacing(1),
		marginTop: Theme.spacing(2),
	},
}));

export default Login;
