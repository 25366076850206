import {colorForProject} from '@Common/colorHash';
import {format} from 'date-fns';

import {fetchGenerics} from './generic';

export class ProjectsOverview {
	project_id: string = '';
	logged_hours: number = 0;
	planned_hours: number = 0;
	estimated_hours: number = 0;

	project_color: string;

	constructor(jsonData: any = {}) {
		Object.assign(this, jsonData);

		this.project_color = colorForProject(this.project_id);
	}
}

export async function fetchProjectsOverview(reportDate: Date) {
	const response = await fetchGenerics<ProjectsOverview>(
		`/projects-overview/${format(reportDate, 'yyyy-MM-dd')}`,
		ProjectsOverview
	);
	return response;
}
