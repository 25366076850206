import ColorHash from 'color-hash';

export const colorHash = new ColorHash({
	lightness: [0.8, 0.75, 0.7, 0.65, 0.6],
	saturation: [0.35, 0.5, 0.65],
});

export const colorForProject = (id: string) => {
	const colorMap: Record<string, string> = {
		'114': '#f2f2f2',
	};

	return colorMap[id] ?? colorHash.hex(id);
};
