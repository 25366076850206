import axios from 'axios';

export interface IResponse<T> {
	success: boolean;
	data: T;
}

export async function fetchGeneric<T>(
	url: string,
	c: new (...args: any) => T
): Promise<T | null> {
	const response = await axios.get<IResponse<T>>(url);

	if (response.data?.success) {
		return new c(response.data.data);
	}

	return null;
}

export async function fetchGenerics<T>(
	url: string,
	c: new (...args: any) => T
): Promise<T[] | null> {
	const response = await axios.get<IResponse<T[]>>(url);

	if (response.data?.success) {
		return response.data.data.map((a) => new c(a));
	}

	return null;
}
