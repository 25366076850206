import {useAuth} from '@Common/Authentication';
import {EEnvironment, versionData} from '@Common/versionData';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {useSnackbar} from 'notistack';
import {useEffect} from 'react';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import {makeStyles} from 'tss-react/mui';

import PlanScreen from './PrivateLayout/PlanScreen';
import PrivateLayout from './PrivateLayout/PrivateLayout';
import ProjectsOverviewScreen from './PrivateLayout/ProjectsOverview/ProjectsOverviewScreen';
import ReportScreen from './PrivateLayout/Report/ReportScreen';
import Login from './PublicLayout/Login';
import PublicLayout from './PublicLayout/PublicLayout';

function App() {
	const {token} = useAuth();
	const {environment} = versionData;
	const {enqueueSnackbar} = useSnackbar();
	const {classes} = useStyles();

	// Display Snackbar for unhandled Axios Errors
	useEffect(() => {
		window.onunhandledrejection = (event) => {
			if (event.reason.name === 'AxiosError') {
				enqueueSnackbar(
					`Ein Fehler ist aufgetreten (${event.reason.message})`,
					{
						variant: 'error',
					}
				);
			} else {
				throw event.reason;
			}
		};
	}, [enqueueSnackbar]);

	return (
		<>
			<BrowserRouter>
				<Routes>
					{token && (
						<Route element={<PrivateLayout />}>
							<Route path='/' element={<PlanScreen />} />
							<Route path='/report' element={<ReportScreen />} />
							<Route
								path='/projects-overview'
								element={<ProjectsOverviewScreen />}
							/>
						</Route>
					)}
					<Route element={<PublicLayout />}>
						<Route
							path='/'
							element={<Navigate replace to='/login' />}
						/>
						<Route path='/login'>
							<Route index element={<Login />} />
						</Route>
					</Route>
				</Routes>
			</BrowserRouter>
			{environment === EEnvironment.DEVELOPMENT && (
				<ReactQueryDevtools initialIsOpen={false} />
			)}
			{environment !== EEnvironment.PRODUCTION && (
				<div
					className={classes.environmentWarning}
					style={{
						backgroundColor:
							environment === EEnvironment.DEVELOPMENT
								? 'red'
								: 'orange',
					}}
				>
					{environment}
				</div>
			)}
		</>
	);
}

const useStyles = makeStyles()((theme) => ({
	environmentWarning: {
		position: 'fixed',
		zIndex: 9999,
		color: 'white',
		left: '50%',
		transform: 'translateX(-50%)',
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		textTransform: 'uppercase',
		borderRadius: '0 0 5px 5px',
		fontWeight: 'bold',
		maxWidth: 600,
		alignSelf: 'center',
	},
}));

export default App;
