import {
	PlannedTime,
	deletePlannedTime,
	savePlannedTime,
} from '@Common/api/plannedTime';
import {Ticket as ITicket} from '@Common/api/tickets';
import {fetchUsers} from '@Common/api/users';
import {customCollisionDetectionAlgorithm} from '@Common/customCollisionDetection';
import {followMouseModifier} from '@Common/customDragModifier';
import Theme from '@Common/Theme';
import {useLocalStorage} from '@Common/useLocalStorage';
import {
	DndContext,
	DragEndEvent,
	DragOverlay,
	DragStartEvent,
} from '@dnd-kit/core';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import ReplayIcon from '@mui/icons-material/Replay';
import {
	AppBar,
	Drawer,
	FormControlLabel,
	FormGroup,
	IconButton,
	Switch,
	Tab,
	Tabs,
	Toolbar,
	Typography,
} from '@mui/material';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {add, getDay} from 'date-fns';
import {useSnackbar} from 'notistack';
import {SyntheticEvent, useCallback, useMemo, useState} from 'react';
import {makeStyles} from 'tss-react/mui';

import AccountIcon from './AccountIcon';
import CompactPlannedTimeView from './CompactPlannedTimeView';
import NavigationDrawer from './NavigationDrawer';
import PlannedTimeView from './PlannedTimeView';
import PlanSettings from './PlanSettings';
import PlanView from './PlanView';
import StatisticsDrawer from './StatisticsDrawer';
import Ticket from './Ticket';
import TicketsDrawer from './TicketsDrawer';

const drawerWidth = 460;

function PlanScreen() {
	const {classes} = useStyles();
	const {enqueueSnackbar} = useSnackbar();
	const queryClient = useQueryClient();

	const [ticketDrawerOpen, setTicketDrawerOpen] = useState(false);
	const [currentTab, setCurrentTab] = useState(0);
	const [navDrawerOpen, setNavDrawerOpen] = useState(false);
	const [draggedTicket, setDraggedTicket] = useState<ITicket | null>(null);
	const [draggedPlannedTime, setDraggedPlannedTime] =
		useState<PlannedTime | null>(null);
	const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(add(new Date(), {months: 2}));

	const [compact, setCompact] = useState(false);

	const users = useQuery({queryKey: ['users'], queryFn: () => fetchUsers()});

	const [displayedUserIds, setDisplayedUserIds] = useLocalStorage(
		'displayedUserIds',
		''
	);

	const toggleTicketDrawer = () => {
		setTicketDrawerOpen((open) => !open);
	};

	const toggleNavDrawer = () => {
		setNavDrawerOpen((open) => !open);
	};

	const handleTabChange = useCallback(
		(_e: SyntheticEvent, newValue: number) => setCurrentTab(newValue),
		[]
	);

	const handleDragStart = useCallback((event: DragStartEvent) => {
		if (event.active.data.current?.type === 'ticket') {
			setDraggedTicket(event.active.data.current.data as ITicket);
			setDraggedPlannedTime(null);
			setAutoScrollEnabled(false);
		} else if (event.active.data.current?.type === 'plannedTime') {
			setDraggedPlannedTime(
				event.active.data.current.data as PlannedTime
			);
			setDraggedTicket(null);
		} else {
			setDraggedTicket(null);
			setDraggedPlannedTime(null);
		}
	}, []);

	const adjustUnplannedTime = useCallback(
		(ticketNumber: string, durationChange: number) => {
			queryClient.setQueryData(['tickets'], (old: ITicket[] = []) => {
				const oldTicket = old.find((t) => t.id === ticketNumber);
				const newTickets = old.filter((t) => t.id !== ticketNumber);

				if (oldTicket) {
					const newTicket = new ITicket({
						...oldTicket,
						unplanned_hours:
							oldTicket.unplanned_hours + durationChange,
					});

					newTickets.push(newTicket);
				}
				return newTickets;
			});
		},
		[queryClient]
	);

	const handleDelete = useCallback(
		async (plannedTime: PlannedTime, future = false) => {
			const result = await deletePlannedTime({
				plannedTime,
				future,
			});

			if (result && !future) {
				queryClient.setQueryData(
					['planned-times'],
					(old: PlannedTime[] = []) =>
						old.filter((pt) => pt._id !== plannedTime._id)
				);

				adjustUnplannedTime(
					plannedTime.ticketNumber,
					plannedTime.duration
				);
			} else {
				queryClient.invalidateQueries({
					queryKey: ['planned-times'],
				});

				queryClient.invalidateQueries({
					queryKey: ['tickets'],
				});
			}

			enqueueSnackbar('Zuweisung gelöscht', {
				variant: 'success',
			});
		},
		[adjustUnplannedTime, enqueueSnackbar, queryClient]
	);

	const handleSave = useCallback(
		async (plannedTime: PlannedTime, showNotification: boolean = true) => {
			const newPlannedTime = await savePlannedTime(plannedTime);

			if (newPlannedTime) {
				queryClient.setQueryData(
					['planned-times'],
					(old: PlannedTime[] = []) => {
						const newItems = old.filter(
							(pt) => pt._id !== newPlannedTime._id
						);
						newItems.push(newPlannedTime);
						return newItems;
					}
				);
			} else {
				queryClient.invalidateQueries({
					queryKey: ['planned-times'],
				});
			}

			if (showNotification) {
				enqueueSnackbar('Ticket zugewiesen', {
					variant: 'success',
				});
			}
		},
		[enqueueSnackbar, queryClient]
	);

	const handleDragEnd = useCallback(
		async (event: DragEndEvent) => {
			const {over, active} = event;

			try {
				if (
					over?.data.current &&
					active.data.current &&
					over.id !== 'ticket-drawer'
				) {
					// Check what is actually being done
					if (active.data.current?.type === 'plannedTime') {
						// Move
						const plannedTime = active.data.current?.data;
						plannedTime.day = over.data.current.day;
						plannedTime.userId = over.data.current.userId;

						await handleSave(plannedTime);
					} else if (active.data.current?.type === 'ticket') {
						// Create
						const plannedTime = new PlannedTime();
						plannedTime.day = over.data.current.day;
						plannedTime.userId = over.data.current.userId;
						plannedTime.ticketNumber = active.data.current.data.id;

						const origUnplannedHours =
							active.data.current.data.unplanned_hours;
						plannedTime.duration = Math.min(8, origUnplannedHours);

						await handleSave(plannedTime);

						adjustUnplannedTime(
							plannedTime.ticketNumber,
							0 - plannedTime.duration
						);
					}
				}
			} catch (error) {
				enqueueSnackbar('Fehler beim Zuweisen', {
					variant: 'error',
				});
				console.error(error);
			} finally {
				setDraggedTicket(null);
				setDraggedPlannedTime(null);
				setAutoScrollEnabled(true);
			}
		},
		[adjustUnplannedTime, enqueueSnackbar, handleSave]
	);

	const handleDurationChange = useCallback(
		async (changedPlannedTime: PlannedTime) => {
			// Find original plannedTime
			const originalPlannedTime = queryClient
				.getQueryData<PlannedTime[]>(['planned-times'])
				?.find((pt) => pt._id === changedPlannedTime._id);
			await handleSave(changedPlannedTime);

			if (originalPlannedTime) {
				adjustUnplannedTime(
					changedPlannedTime.ticketNumber,
					originalPlannedTime.duration - changedPlannedTime.duration
				);
			}
		},
		[adjustUnplannedTime, handleSave, queryClient]
	);

	const handleNewDays = useCallback(
		async (origPlannedTime: PlannedTime, numberOfDays: number) => {
			for (let i = 1; i <= numberOfDays; i++) {
				const newDay = add(origPlannedTime.day, {days: i});

				// Only create tickets for weekdays
				if (getDay(newDay) !== 0 && getDay(newDay) !== 6) {
					const plannedTime = new PlannedTime();
					plannedTime.day = newDay;
					plannedTime.userId = origPlannedTime.userId;
					plannedTime.ticketNumber = origPlannedTime.ticketNumber;
					plannedTime.duration = origPlannedTime.duration;

					await handleSave(plannedTime, false);

					adjustUnplannedTime(
						plannedTime.ticketNumber,
						0 - plannedTime.duration
					);
				}
			}

			enqueueSnackbar('Ticket zugewiesen', {
				variant: 'success',
			});
		},
		[adjustUnplannedTime, enqueueSnackbar, handleSave]
	);

	const handleReloadData = useCallback(() => {
		queryClient.invalidateQueries();
	}, [queryClient]);

	const displayedUsers = useMemo(() => {
		if (!users.data) {
			return [];
		}

		if (displayedUserIds === '') {
			return users.data;
		}

		const toDisplay = [];
		const parsedIds = displayedUserIds.split(',');
		for (const userId of parsedIds) {
			const found = users.data.find((u) => u.id === userId);
			if (found) {
				toDisplay.push(found);
			}
		}
		return toDisplay;
	}, [displayedUserIds, users.data]);

	return (
		<DndContext
			onDragStart={handleDragStart}
			onDragEnd={handleDragEnd}
			autoScroll={autoScrollEnabled}
			collisionDetection={customCollisionDetectionAlgorithm}
			modifiers={[followMouseModifier]}
		>
			<AppBar position='sticky' className={classes.appBar}>
				<Toolbar>
					<IconButton onClick={toggleNavDrawer} color='inherit'>
						<MenuIcon />
					</IconButton>
					<Typography variant='h6'>
						ELEPHANTS 5 Team Planner
					</Typography>
					<div className={classes.spacer} />
					<FormGroup>
						<FormControlLabel
							control={
								<Switch
									color='secondary'
									checked={compact}
									onChange={(e) =>
										setCompact(e.target.checked)
									}
								/>
							}
							label='Kompakt'
						/>
					</FormGroup>
					<IconButton onClick={handleReloadData} color='inherit'>
						<ReplayIcon />
					</IconButton>
					<PlanSettings
						startDate={startDate}
						endDate={endDate}
						onStartDateChange={(n) => {
							setStartDate(n);
							queryClient.invalidateQueries({
								queryKey: ['planned-times'],
							});
						}}
						onEndDateChange={(n) => {
							setEndDate(n);
							queryClient.invalidateQueries({
								queryKey: ['planned-times'],
							});
						}}
						allUsers={users.data ?? []}
						displayedUserIds={displayedUserIds}
						onDisplayedUserIdsChange={(n) => setDisplayedUserIds(n)}
					/>
					<AccountIcon />
					<IconButton onClick={toggleTicketDrawer} color='inherit'>
						{ticketDrawerOpen ? (
							<ChevronRightIcon />
						) : (
							<ChevronLeftIcon />
						)}
					</IconButton>
				</Toolbar>
			</AppBar>
			<Drawer variant='persistent' anchor='left' open={navDrawerOpen}>
				<NavigationDrawer />
			</Drawer>
			<div
				className={classes.mainContainer}
				style={{
					width: ticketDrawerOpen
						? `calc(100% - ${drawerWidth}px)`
						: '100%',
				}}
			>
				<PlanView
					onChange={handleDurationChange}
					onDelete={handleDelete}
					onNewDays={handleNewDays}
					startDate={startDate}
					endDate={endDate}
					users={displayedUsers}
					compact={compact}
				/>
			</div>
			<Drawer
				variant='persistent'
				anchor='right'
				open={ticketDrawerOpen}
				className={classes.ticketDrawer}
			>
				<Toolbar />
				<Tabs
					value={currentTab}
					onChange={handleTabChange}
					variant='fullWidth'
				>
					<Tab label='Tickets' value={0} />
					<Tab label='Statistik' value={1} />
				</Tabs>
				{currentTab === 0 && <TicketsDrawer />}
				{currentTab === 1 && (
					<StatisticsDrawer startDate={startDate} endDate={endDate} />
				)}
			</Drawer>
			<DragOverlay
				className={classes.dragContainer}
				zIndex={9999}
				dropAnimation={null}
			>
				{draggedTicket ? (
					<Ticket
						ticket={draggedTicket}
						width={
							compact ? Theme.compactColWidth : Theme.mainColWidth
						}
						compact={compact}
					/>
				) : null}
				{draggedPlannedTime && !compact ? (
					<PlannedTimeView
						plannedTime={draggedPlannedTime}
						fullHeight={Theme.mainRowHeight}
						width={Theme.mainColWidth}
						isInDragOverlay
					/>
				) : null}
				{draggedPlannedTime && compact ? (
					<CompactPlannedTimeView
						plannedTime={draggedPlannedTime}
						fullHeight={Theme.mainRowHeight}
						width={Theme.compactColWidth}
						isInDragOverlay
					/>
				) : null}
			</DragOverlay>
		</DndContext>
	);
}

const useStyles = makeStyles()((theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	spacer: {
		flexGrow: 1,
	},
	mainContainer: {
		flexGrow: 1,
	},
	ticketDrawer: {
		width: drawerWidth,
		'& .MuiDrawer-paper': {
			width: drawerWidth,
		},
	},
	dragContainer: {
		zIndex: theme.zIndex.drawer + 3,
		cursor: 'grabbing',
		userSelect: 'none',
	},
}));

export default PlanScreen;
