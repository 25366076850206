import axios from 'axios';
import {startOfDay} from 'date-fns';
import {fromZonedTime} from 'date-fns-tz';

import {fetchGenerics} from './generic';

export class BlockedTime {
	_id: string = '';
	userId: string = '';
	day: Date = new Date();

	readonly: boolean = false;

	constructor(jsonData: any = {}) {
		Object.assign(this, jsonData);

		if (jsonData.day) {
			this.day = new Date(jsonData.day);
		}
	}
}

export async function fetchBlockedTimes(startDate: Date) {
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const utcDate = fromZonedTime(startOfDay(startDate), timeZone);
	const response = await fetchGenerics<BlockedTime>(
		`/blocked-times/?startDate=${utcDate.toJSON()}`,
		BlockedTime
	);
	return response;
}

export async function saveBlockedTime(
	blockedTime: BlockedTime
): Promise<BlockedTime | null> {
	try {
		const response = await axios.post('/blocked-times/new', {
			...blockedTime,
			_id: undefined,
		});

		if (response.data.success === true && response.data.data) {
			return new BlockedTime(response.data.data);
		}
	} catch (e) {
		console.error('Error while saving blocked time', e);
		throw e;
	}

	return null;
}

export async function deleteBlockedTime(
	blockedTime: BlockedTime
): Promise<boolean> {
	try {
		const response = await axios.delete(
			`/blocked-times/${blockedTime._id}`
		);

		return response.data.success;
	} catch (e) {
		console.error('Error while deleting blocked time', e);
		throw e;
	}
}
