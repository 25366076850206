import {User} from '@Common/api/users';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SettingsIcon from '@mui/icons-material/Settings';
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import {useMemo, useState} from 'react';
import {makeStyles} from 'tss-react/mui';

interface Props {
	startDate: Date;
	endDate: Date;
	onStartDateChange: (newDate: Date) => void;
	onEndDateChange: (newDate: Date) => void;
	allUsers: User[];
	displayedUserIds: string;
	onDisplayedUserIdsChange: (newIds: string) => void;
}

export default function PlanSettings(props: Props) {
	const {classes} = useStyles();
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const displayedUserIds = props.displayedUserIds.split(',');

	const handleUserChecked = (user: User, checked: boolean) => {
		let userIds = displayedUserIds;
		if (checked) {
			userIds.push(user.id);
		} else {
			userIds = userIds.filter((u) => u !== user.id);
		}

		props.onDisplayedUserIdsChange(userIds.join(','));
	};

	const displayedUsers = useMemo(() => {
		const toDisplay = [];
		for (const userId of displayedUserIds) {
			const found = props.allUsers.find((u) => u.id === userId);
			if (found) {
				toDisplay.push(found);
			}
		}

		// Append all other Users
		const otherUsers = props.allUsers.filter(
			(u) => !displayedUserIds.includes(u.id)
		);
		return [...toDisplay, ...otherUsers];
	}, [displayedUserIds, props.allUsers]);

	const moveUserUp = (user: User) => {
		const origIndex = displayedUserIds.findIndex((u) => u === user.id);

		if (origIndex) {
			displayedUserIds[origIndex] = displayedUserIds.splice(
				origIndex - 1,
				1,
				displayedUserIds[origIndex]
			)[0];
		}

		props.onDisplayedUserIdsChange(displayedUserIds.join(','));
	};

	const moveUserDown = (user: User) => {
		const origIndex = displayedUserIds.findIndex((u) => u === user.id);

		if (origIndex) {
			displayedUserIds[origIndex] = displayedUserIds.splice(
				origIndex + 1,
				1,
				displayedUserIds[origIndex]
			)[0];
		}

		props.onDisplayedUserIdsChange(displayedUserIds.join(','));
	};

	return (
		<>
			<IconButton onClick={handleClickOpen} color='inherit'>
				<SettingsIcon color='inherit' />
			</IconButton>
			<Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
				<DialogTitle>Einstellungen</DialogTitle>
				<DialogContent>
					<div className={classes.dateContainer}>
						<DatePicker
							value={props.startDate}
							label='Von'
							onChange={(v) => v && props.onStartDateChange(v)}
							className={classes.datePicker}
						/>
						{}
						<DatePicker
							value={props.endDate}
							label='Bis'
							onChange={(v) => v && props.onEndDateChange(v)}
							className={classes.datePicker}
						/>
					</div>
					<Typography variant='h6'>Benutzer</Typography>
					<List>
						{displayedUsers.map((u, ui) => (
							<ListItem
								key={u.id}
								secondaryAction={
									<>
										{displayedUserIds.includes(u.id) && (
											<>
												{ui !== 0 && (
													<IconButton
														edge='end'
														onClick={() =>
															moveUserUp(u)
														}
													>
														<KeyboardArrowUpIcon />
													</IconButton>
												)}
												{ui <
													displayedUserIds.length -
														2 && (
													<IconButton
														edge='end'
														onClick={() =>
															moveUserDown(u)
														}
													>
														<KeyboardArrowDownIcon />
													</IconButton>
												)}
											</>
										)}
									</>
								}
							>
								<ListItemButton>
									<ListItemIcon>
										<Checkbox
											edge='start'
											checked={displayedUserIds.includes(
												u.id
											)}
											disableRipple
											onChange={(e) =>
												handleUserChecked(
													u,
													e.target.checked
												)
											}
										/>
									</ListItemIcon>
									<ListItemText
										primary={`${u.firstname} ${u.lastname}`}
									/>
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Schließen</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

const useStyles = makeStyles()((theme) => ({
	dateContainer: {
		display: 'flex',
	},
	datePicker: {
		margin: theme.spacing(),
	},
}));
