import {colorForProject} from '@Common/colorHash';

import {fetchGenerics} from './generic';

export class Project {
	id: string = '';
	name: string = '';
	parent_id: string = '';
	children?: Project[] = [];

	project_color: string;

	constructor(jsonData: any = {}) {
		Object.assign(this, jsonData);

		const children = [];
		if (jsonData.children) {
			for (const jsonChild of jsonData.children) {
				children.push(new Project(jsonChild));
			}
		}
		this.children = children;

		this.project_color = colorForProject(this.id);
	}
}

export async function fetchProjects() {
	const response = await fetchGenerics<Project>('/projects/', Project);
	return response;
}

// Helpers
export function findProject(
	projects: Project[],
	projectId: string
): Project | undefined {
	for (const project of projects) {
		if (project.id === projectId) {
			return project;
		}

		const childResult = findProject(project.children ?? [], projectId);
		if (childResult) {
			return childResult;
		}
	}

	return undefined;
}

export function allProjectIds(projects: Project[]): string[] {
	let ids: string[] = [];

	for (const project of projects) {
		ids.push(project.id);
		ids = ids.concat(allProjectIds(project.children ?? []));
	}

	return ids;
}
