import {fetchBlockedTimes} from '@Common/api/blockedTime';
import {fetchMilestones} from '@Common/api/milestone';
import {PlannedTime, fetchPlannedTimes} from '@Common/api/plannedTime';
import {User} from '@Common/api/users';
import Theme from '@Common/Theme';
import {useQuery} from '@tanstack/react-query';
import {
	add,
	endOfDay,
	isAfter,
	isBefore,
	isSameDay,
	startOfDay,
} from 'date-fns';
import {
	memo,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {makeStyles} from 'tss-react/mui';

import DateHeader from './DateHeader';
import SingleDay from './SingleDay';

interface Props {
	users: User[];
	startDate: Date;
	endDate: Date;
	onChange: (newPlannedTime: PlannedTime) => void;
	onDelete: (plannedTime: PlannedTime, future?: boolean) => void;
	onNewDays?: (
		plannedTime: PlannedTime,
		numberOfDays: number
	) => Promise<void>;
	compact?: boolean;
}

const columnHeight = Theme.mainRowHeight;
const refetchInterval = 20 * 1000;

function PlanView(props: Props) {
	const {classes} = useStyles();
	const dateRowRef = useRef<HTMLDivElement>(null);
	const [dateRowHeight, setDateRowHeight] = useState(0);

	const plannedTimes = useQuery({
		queryKey: ['planned-times'],
		queryFn: () => fetchPlannedTimes(props.startDate),
		refetchInterval,
		refetchIntervalInBackground: false,
	});
	const blockedTimes = useQuery({
		queryKey: ['blocked-times'],
		queryFn: () => fetchBlockedTimes(props.startDate),
		refetchInterval,
		refetchIntervalInBackground: false,
	});
	const milestones = useQuery({
		queryKey: ['milestones'],
		queryFn: () => fetchMilestones(),
		refetchInterval,
		refetchIntervalInBackground: false,
	});

	const listAllDays = (start: Date, end: Date) => {
		start = startOfDay(start);
		end = endOfDay(end);

		const days = [];

		let currentDay = new Date(start);
		while (
			(isSameDay(currentDay, start) || isAfter(currentDay, start)) &&
			isBefore(currentDay, end)
		) {
			days.push(currentDay);
			currentDay = add(currentDay, {days: 1});
		}

		return days;
	};

	const {startDate, endDate} = props;
	const allDays = useMemo(
		() => listAllDays(startDate, endDate),
		[startDate, endDate]
	);

	useLayoutEffect(() => {
		if (dateRowRef.current && milestones.data) {
			setDateRowHeight(dateRowRef.current.offsetHeight);
		}
	}, [milestones.data, props.compact]);

	const {refetch: refetchPlannedTimes} = plannedTimes;
	const {refetch: refetchBlockedTimes} = blockedTimes;
	useEffect(() => {
		if (props.startDate && props.endDate) {
			refetchPlannedTimes();
			refetchBlockedTimes();
		}
	}, [
		props.startDate,
		props.endDate,
		refetchPlannedTimes,
		refetchBlockedTimes,
	]);

	const columnWidth = props.compact
		? Theme.compactColWidth
		: Theme.mainColWidth;

	return (
		<div className={classes.container}>
			<div
				className={classes.nameCol}
				style={{paddingTop: dateRowHeight}}
			>
				{props.users.map((u, i) => (
					<div className={classes.nameCell} key={i}>
						<span className={classes.nameLabel}>{u.firstname}</span>
					</div>
				))}
			</div>
			<div className={classes.daysContainer}>
				<div className={classes.dateRow} ref={dateRowRef}>
					{allDays.map((d, di) => (
						<DateHeader
							key={di}
							width={columnWidth}
							date={d}
							milestones={milestones.data ?? []}
							compact={props.compact}
						/>
					))}
				</div>
				{props.users.map((u, i) => (
					<div key={i} className={classes.row}>
						{allDays.map((d, di) => (
							<SingleDay
								key={di}
								userId={u.id}
								day={d}
								plannedTimes={plannedTimes.data ?? []}
								blockedTimes={blockedTimes.data ?? []}
								fullHeight={columnHeight}
								width={columnWidth}
								onChange={props.onChange}
								onDelete={props.onDelete}
								onNewDays={props.onNewDays}
								compact={props.compact}
							/>
						))}
					</div>
				))}
			</div>
		</div>
	);
}

const useStyles = makeStyles()((theme) => ({
	container: {
		userSelect: 'none',
		display: 'flex',
	},
	nameCol: {
		backgroundColor: theme.palette.primary.main,
	},
	daysContainer: {
		overflow: 'scroll',
	},
	row: {
		display: 'flex',
		height: columnHeight + 6, // add padding of planned time view
	},
	dateRow: {
		display: 'flex',
	},
	nameCell: {
		width: 40,
		height: columnHeight + 6, // add padding of planned time view
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		flexShrink: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderBottom: '1px solid white',
	},
	nameLabel: {
		display: 'block',
		transform: 'rotate(180deg)',
		textOrientation: 'sideways',
		writingMode: 'vertical-rl',
	},
}));

export default memo(PlanView);
