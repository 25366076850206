import {CollisionDetection, rectIntersection} from '@dnd-kit/core';

export const customCollisionDetectionAlgorithm: CollisionDetection = ({
	droppableContainers,
	...args
}) => {
	// First, let's see if the ticket-drawer is intersecting
	const ticketDrawerCollisions = rectIntersection({
		...args,
		droppableContainers: droppableContainers.filter(
			({id}) => id === 'ticket-drawer'
		),
	});

	if (ticketDrawerCollisions.length > 0) {
		return ticketDrawerCollisions;
	}

	// Compute other collisions
	return rectIntersection({
		...args,
		droppableContainers: droppableContainers.filter(
			({id}) => id !== 'ticket-drawer'
		),
	});
};
