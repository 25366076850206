import {
	allProjectIds,
	fetchProjects,
	findProject,
	Project,
} from '@Common/api/projects';
import {fetchTickets} from '@Common/api/tickets';
import Draggable from '@Common/Draggable';
import {useDroppable} from '@dnd-kit/core';
import SearchIcon from '@mui/icons-material/Search';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import {memo, useMemo, useState} from 'react';
import {makeStyles} from 'tss-react/mui';

import Ticket from './Ticket';

const refetchInterval = 5 * 60 * 1000;

function TicketsDrawer() {
	const {classes} = useStyles();

	const [filterProjectId, setFilterProjectId] = useState('all');
	const [showOnlyUnplannedTickets, setShowOnlyUnplannedTickets] =
		useState(true);
	const [filterText, setFilterText] = useState('');

	const tickets = useQuery({
		queryKey: ['tickets'],
		queryFn: () => fetchTickets(),
		refetchInterval,
		refetchIntervalInBackground: false,
	});
	const projects = useQuery({
		queryKey: ['projects'],
		queryFn: () => fetchProjects(),
		refetchInterval,
		refetchIntervalInBackground: false,
	});

	const displayedTickets = useMemo(() => {
		let toDisplay = tickets.data ?? [];

		if (showOnlyUnplannedTickets) {
			toDisplay = toDisplay.filter((t) => t.unplanned_hours > 0);
		}

		if (filterProjectId !== 'all') {
			const project = findProject(projects.data ?? [], filterProjectId);

			if (project) {
				const allFilterIds = allProjectIds([project]);
				console.log(allFilterIds);
				toDisplay = toDisplay.filter((t) =>
					allFilterIds.includes(t.project_id)
				);
			}
		}

		if (filterText !== '') {
			const lowerFilterText = filterText.toLowerCase();
			toDisplay = toDisplay.filter(
				(t) =>
					t.id.includes(filterText) ||
					t.subject.toLowerCase().includes(lowerFilterText)
			);
		}

		return toDisplay.sort((a, b) => a.id.localeCompare(b.id));
	}, [
		filterProjectId,
		filterText,
		projects.data,
		showOnlyUnplannedTickets,
		tickets.data,
	]);

	const renderProjectOption = (
		project: Project,
		level: number = 1
	): React.ReactNode => [
		<MenuItem value={project.id}>
			{new Array(level).join('- ')}
			{project.name}
		</MenuItem>,
		project.children?.map((c) => renderProjectOption(c, level + 1)),
	];

	return (
		<div className={classes.container}>
			<Typography variant='h6'>Filter</Typography>
			<div className={classes.filterContainer}>
				<FormControl fullWidth className={classes.projectFilter}>
					<InputLabel id='project-select-label'>Projekt</InputLabel>
					<Select
						labelId='project-select-label'
						id='project-select'
						value={filterProjectId}
						label='Projekt'
						onChange={(e) => setFilterProjectId(e.target.value)}
						size='small'
					>
						<MenuItem value='all'>ALLE</MenuItem>
						{projects.data?.map((c) => renderProjectOption(c))}
					</Select>
				</FormControl>
				<TextField
					label='Suche'
					variant='outlined'
					InputProps={{
						endAdornment: (
							<InputAdornment position='start'>
								<SearchIcon />
							</InputAdornment>
						),
					}}
					fullWidth
					value={filterText}
					size='small'
					onChange={(e) => setFilterText(e.target.value)}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={showOnlyUnplannedTickets}
							onChange={(e) =>
								setShowOnlyUnplannedTickets(e.target.checked)
							}
							size='small'
						/>
					}
					label='Nur unverplante Tickets'
					className={classes.unplannedCheckbox}
				/>
			</div>
			<Typography variant='h6'>Tickets</Typography>
			{displayedTickets?.map((t, i) => (
				<Draggable ticket={t} key={i} type='ticket' id={t.id}>
					<Ticket ticket={t} />
				</Draggable>
			))}
		</div>
	);
}

const useStyles = makeStyles()((theme) => ({
	container: {
		padding: theme.spacing(),
	},
	projectFilter: {
		marginTop: theme.spacing(),
		marginBottom: theme.spacing(),
	},
	unplannedCheckbox: {
		marginTop: theme.spacing(),
		marginBottom: theme.spacing(),
	},
	filterContainer: {
		paddingLeft: theme.spacing(),
		paddingRight: theme.spacing(),
	},
}));

const TicketsDrawerMemo = memo(TicketsDrawer);

const TicketsDrawerWrapper = () => {
	const {setNodeRef} = useDroppable({
		id: 'ticket-drawer',
	});

	return (
		<div ref={setNodeRef}>
			<TicketsDrawerMemo />
		</div>
	);
};

export default memo(TicketsDrawerWrapper);
