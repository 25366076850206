import {Report, SingleReport, fetchReport} from '@Common/api/reports';
import MenuIcon from '@mui/icons-material/Menu';
import {
	AppBar,
	Button,
	CircularProgress,
	Drawer,
	FormControlLabel,
	IconButton,
	Paper,
	Switch,
	TextField,
	Toolbar,
	Typography,
} from '@mui/material';
import {DataGrid, GridColDef, GridToolbar} from '@mui/x-data-grid';
import {formatDate} from 'date-fns';
import {useMemo, useState} from 'react';
import {makeStyles} from 'tss-react/mui';

import AccountIcon from '../AccountIcon';
import NavigationDrawer from '../NavigationDrawer';

export default function ReportScreen() {
	const {classes} = useStyles();

	const [navDrawerOpen, setNavDrawerOpen] = useState(false);
	const [ticketNumber, setTicketNumber] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);
	const [singleReport, setSingleReport] = useState<SingleReport | null>(null);

	const toggleNavDrawer = () => {
		setNavDrawerOpen((open) => !open);
	};

	const handleGenerate = async () => {
		setIsLoading(true);

		try {
			const loadedTimeEntries = await fetchReport(ticketNumber);
			if (loadedTimeEntries !== null) {
				setSingleReport(loadedTimeEntries);
			}
		} catch (e) {
			console.error(e);
		} finally {
			setIsLoading(false);
		}
	};

	const columns: GridColDef<Report>[] = [
		{
			field: 'spent_on',
			headerName: 'Datum',
			valueGetter: (_params, row) => {
				return formatDate(row.spent_on, 'dd.MM.yyyy');
			},
		},
		{field: 'project_name', headerName: 'Projekt', flex: 1},
		{field: 'work_package_id', headerName: 'Ticket #'},
		{field: 'work_package_subject', headerName: 'Ticket', flex: 1},
		{field: 'comments', headerName: 'Kommentar', flex: 1},
		{
			field: 'name',
			headerName: 'Name',
			valueGetter: (_params, row) => {
				return `${row.user_firstname} ${row.user_lastname}`;
			},
			flex: 1,
		},
		{
			field: 'hours',
			headerName: 'Stunden',
			valueGetter: (_params, row) => {
				return formatDuration(row.hours);
			},
		},
	];

	const totalHours = useMemo(() => {
		if (singleReport === null) {
			return 0;
		}

		let sum = 0;
		for (const entry of singleReport.time_entries) {
			sum += entry.hours;
		}
		return sum;
	}, [singleReport]);

	const formatDuration = (duration: number) => {
		return duration.toLocaleString('de-DE', {
			maximumFractionDigits: 1,
		});
	};

	return (
		<>
			<AppBar position='sticky' className={classes.appBar}>
				<Toolbar>
					<IconButton onClick={toggleNavDrawer} color='inherit'>
						<MenuIcon />
					</IconButton>
					<Typography variant='h6'>
						ELEPHANTS 5 Team Planner
					</Typography>
					<div className={classes.spacer} />
					<AccountIcon />
				</Toolbar>
			</AppBar>
			<Drawer variant='persistent' anchor='left' open={navDrawerOpen}>
				<NavigationDrawer />
			</Drawer>
			<Paper className={classes.paper}>
				<Typography variant='h3' mb={1}>
					Stundenauswertung Generieren
				</Typography>
				<TextField
					value={ticketNumber}
					onChange={(e) => setTicketNumber(e.target.value)}
					label='Ticketnummer'
					placeholder='1234'
					size='small'
				/>
				<FormControlLabel
					disabled
					control={<Switch defaultChecked />}
					label='Untertickets inkludieren'
					className={classes.switch}
				/>
				<span className={classes.generateButtonWrapper}>
					<Button
						variant='contained'
						onClick={handleGenerate}
						className={classes.generateButton}
						disabled={isLoading}
					>
						Generieren
					</Button>
					{isLoading && (
						<CircularProgress
							size={24}
							sx={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								marginTop: '-12px',
								marginLeft: '-12px',
							}}
						/>
					)}
				</span>
			</Paper>
			{singleReport !== null && (
				<>
					<Paper className={classes.paper}>
						<Typography variant='h4' mb={1}>
							Stundenauswertung
						</Typography>
						<Typography>
							Gebucht: {formatDuration(totalHours)} h<br />
							Geplant: {formatDuration(
								singleReport.planned_time
							)}{' '}
							h (ab heute)
							<br />
							<strong>
								Gesamt:{' '}
								{formatDuration(
									totalHours + singleReport.planned_time
								)}{' '}
								h
							</strong>
							<br />
							Geschätzt:{' '}
							{formatDuration(singleReport.estimated_time)} h
							(offen:{' '}
							{formatDuration(
								singleReport.estimated_time -
									(totalHours + singleReport.planned_time)
							)}
							h)
						</Typography>
					</Paper>
					<Paper className={classes.paper}>
						<DataGrid
							rows={singleReport.time_entries}
							columns={columns}
							slots={{toolbar: GridToolbar}}
						/>
					</Paper>
				</>
			)}
		</>
	);
}

const useStyles = makeStyles()((theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	spacer: {
		flexGrow: 1,
	},
	paper: {
		margin: theme.spacing(2),
		padding: theme.spacing(1),
	},
	generateButtonWrapper: {
		position: 'relative',
	},
	generateButton: {
		marginLeft: theme.spacing(1),
	},
	switch: {
		marginLeft: theme.spacing(1),
	},
}));
