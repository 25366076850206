import {Ticket as ITicket} from '@Common/api/tickets';
import {Typography} from '@mui/material';
import {memo} from 'react';
import {makeStyles} from 'tss-react/mui';

interface Props {
	ticket: ITicket;
	width?: number;
	compact?: boolean;
}

function Ticket(props: Props) {
	const {classes} = useStyles();

	return (
		<div
			className={classes.ticketContainer}
			style={{
				backgroundColor: props.ticket.project_color,
				maxWidth: props.width ?? 'unset',
			}}
		>
			{props.compact !== true && (
				<>
					<Typography variant='caption' component='div'>
						{props.ticket.project_name}
					</Typography>
					<div className={classes.topRow}>
						<Typography variant='subtitle2' component='div'>
							#{props.ticket.id}
						</Typography>
						<Typography variant='body2' component='div'>
							{props.ticket.formattedUnplannedHours()}h
						</Typography>
					</div>
					<Typography
						variant='body2'
						component='div'
						className={classes.ticketName}
					>
						{props.ticket.subject}
					</Typography>
				</>
			)}
			{props.compact === true && (
				<>
					<Typography
						variant='subtitle2'
						component='div'
						className={classes.compactLabel}
					>
						#{props.ticket.id}
					</Typography>
				</>
			)}
		</div>
	);
}

const useStyles = makeStyles()((theme) => ({
	ticketContainer: {
		borderRadius: 3,
		margin: 2,
		marginBottom: 4,
		padding: 4,
		boxSizing: 'border-box',
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: 'rgba(0,0,0, 0.2)',
		display: 'flex',
		flexDirection: 'column',
	},
	topRow: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	ticketName: {
		flex: 1,
	},
	compactLabel: {
		transform: 'rotate(180deg)',
		textOrientation: 'sideways',
		writingMode: 'vertical-rl',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
}));

export default memo(Ticket);
