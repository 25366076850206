import {useAuth} from '@Common/Authentication';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import {IconButton, Menu, MenuItem} from '@mui/material';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

function AccountIcon() {
	const {logout} = useAuth();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const navigate = useNavigate();

	const closeMenu = function () {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton
				color='inherit'
				onClick={(e) => setAnchorEl(e.currentTarget)}
			>
				<PermIdentityIcon />
			</IconButton>
			<Menu anchorEl={anchorEl} open={open} onClose={closeMenu}>
				<MenuItem
					onClick={() => {
						logout();
						closeMenu();
						navigate('/login');
					}}
				>
					Abmelden
				</MenuItem>
			</Menu>
		</>
	);
}

export default AccountIcon;
