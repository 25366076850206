import axios from 'axios';
import {startOfDay} from 'date-fns';
import {fromZonedTime} from 'date-fns-tz';

import {fetchGenerics} from './generic';
import {Ticket} from './tickets';

export class PlannedTime {
	_id: string = '';
	ticketNumber: string = '';
	userId: string = '';
	day: Date = new Date();
	duration: number = 0;
	ticket: Ticket = new Ticket();

	readonly: boolean = false;

	constructor(jsonData: any = {}) {
		Object.assign(this, jsonData);
		if (jsonData.day) {
			this.day = new Date(jsonData.day);
		}

		if (jsonData.ticket) {
			this.ticket = new Ticket(jsonData.ticket);
		}
	}
}

export async function fetchPlannedTimes(startDate: Date) {
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const utcDate = fromZonedTime(startOfDay(startDate), timeZone);
	const response = await fetchGenerics<PlannedTime>(
		`/planned-times/?startDate=${utcDate.toJSON()}`,
		PlannedTime
	);
	return response;
}

export async function savePlannedTime(
	plannedTime: PlannedTime
): Promise<PlannedTime | null> {
	try {
		const response = await axios.post(
			`/planned-times/${
				plannedTime._id === '' ? 'new' : plannedTime._id
			}`,
			{
				...plannedTime,
				_id: plannedTime._id === '' ? undefined : plannedTime._id,
			}
		);

		if (response.data.success === true && response.data.data) {
			return new PlannedTime(response.data.data);
		}
	} catch (e) {
		console.error('Error while saving planned time', e);
		throw e;
	}

	return null;
}

export async function deletePlannedTime(variables: {
	plannedTime: PlannedTime;
	future?: boolean;
}): Promise<boolean> {
	try {
		const response = await axios.delete(
			`/planned-times/${variables.plannedTime._id}?future=${
				variables.future === true ? 'true' : 'false'
			}`
		);

		return response.data.success;
	} catch (e) {
		console.error('Error while deleting planned time', e);
		throw e;
	}
}
