import {
	BlockedTime,
	deleteBlockedTime,
	saveBlockedTime,
} from '@Common/api/blockedTime';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';
import {useCallback, useState} from 'react';

interface Props {
	userId: string;
	date: Date;
	blockedTime?: BlockedTime;
}

export default function BlockedDayContent(props: Props) {
	const queryClient = useQueryClient();

	const [contextMenu, setContextMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	const handleContextMenu = (event: React.MouseEvent) => {
		event.preventDefault();

		if (props.blockedTime?.readonly) {
			return;
		}

		setContextMenu(
			contextMenu === null
				? {
						mouseX: event.clientX + 2,
						mouseY: event.clientY - 6,
					}
				: // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
					// Other native context menus might behave different.
					// With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
					null
		);
	};

	const handleClose = useCallback(() => {
		setContextMenu(null);
	}, []);

	const handleDeleteBlockedTime = useCallback(async () => {
		if (props.blockedTime) {
			await deleteBlockedTime(props.blockedTime);
			queryClient.invalidateQueries({
				queryKey: ['blocked-times'],
			});
		}
		handleClose();
	}, [handleClose, props.blockedTime, queryClient]);

	const handleNewBlockedTime = useCallback(async () => {
		const blockedTime = new BlockedTime({
			userId: props.userId,
			day: props.date,
		});
		await saveBlockedTime(blockedTime);
		queryClient.invalidateQueries({
			queryKey: ['blocked-times'],
		});

		handleClose();
	}, [handleClose, props.date, props.userId, queryClient]);

	return (
		<div onContextMenu={handleContextMenu} style={{flex: 1}}>
			<Menu
				open={contextMenu !== null}
				onClose={handleClose}
				anchorReference='anchorPosition'
				anchorPosition={
					contextMenu !== null
						? {
								top: contextMenu.mouseY,
								left: contextMenu.mouseX,
							}
						: undefined
				}
			>
				{props.blockedTime && (
					<MenuItem onClick={handleDeleteBlockedTime}>
						<ListItemIcon>
							<LockOpenIcon fontSize='small' />
						</ListItemIcon>
						<ListItemText>Entsperren</ListItemText>
					</MenuItem>
				)}
				{!props.blockedTime && (
					<MenuItem onClick={handleNewBlockedTime}>
						<ListItemIcon>
							<LockIcon fontSize='small' />
						</ListItemIcon>
						<ListItemText>Sperren</ListItemText>
					</MenuItem>
				)}
			</Menu>
		</div>
	);
}
