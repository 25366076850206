import {fetchPlannedTimes} from '@Common/api/plannedTime';
import {useQuery} from '@tanstack/react-query';
import {isAfter} from 'date-fns';
import {useMemo} from 'react';
import {makeStyles} from 'tss-react/mui';

interface Props {
	startDate: Date;
	endDate: Date;
}

function StatisticsDrawer(props: Props) {
	const {classes} = useStyles();

	const plannedTimes = useQuery({
		queryKey: ['planned-times'],
		queryFn: () => fetchPlannedTimes(props.startDate),
	});

	const statistics = useMemo(() => {
		const projectMap: {[key: string]: number} = {};
		for (const plannedTime of plannedTimes?.data ?? []) {
			// Skip plannedTimes that are outside our selected range
			if (isAfter(plannedTime.day, props.endDate)) {
				continue;
			}

			projectMap[plannedTime.ticket.project_name] =
				(projectMap[plannedTime.ticket.project_name] ?? 0) +
				plannedTime.duration;
		}

		const statistics = [];
		for (const projectName of Object.keys(projectMap)) {
			statistics.push({
				projectName,
				hours: projectMap[projectName] ?? 0,
			});
		}

		return statistics;
	}, [plannedTimes.data, props.endDate]);

	return (
		<div className={classes.container}>
			<ul>
				{statistics.map((project) => (
					<li key={project.projectName}>
						{project.projectName}:{' '}
						{project.hours.toLocaleString('de-DE', {
							maximumFractionDigits: 1,
						})}
						h
					</li>
				))}
			</ul>
		</div>
	);
}

const useStyles = makeStyles()((theme) => ({
	container: {
		padding: theme.spacing(),
	},
}));

export default StatisticsDrawer;
