import {Milestone} from '@Common/api/milestone';
import {Typography} from '@mui/material';
import {format, getDay, isSameDay} from 'date-fns';
import {useMemo} from 'react';
import {makeStyles} from 'tss-react/mui';

import MilestoneLabel from './MilestoneLabel';

interface Props {
	date: Date;
	width: number;
	milestones: Milestone[];
	compact?: boolean;
}

export default function DateHeader(props: Props) {
	const {classes} = useStyles();

	const filterMilestones = (
		milestones: Milestone[],
		day: Date
	): Milestone[] => {
		return milestones.filter((m) => isSameDay(m.due_date, day));
	};

	const milestones = useMemo(
		() => filterMilestones(props.milestones, props.date),
		[props.milestones, props.date]
	);

	const isWeekend = getDay(props.date) === 0 || getDay(props.date) === 6;
	const isCurrentDay = isSameDay(props.date, new Date());

	const dateFormat = 'EEE. dd. MM. yyyy';

	return (
		<div
			className={`${classes.dateHeader} ${
				isWeekend ? classes.weekend : ''
			} ${isCurrentDay ? classes.current : ''}`}
			style={{width: props.width}}
		>
			<Typography className={classes.dateLabel}>
				{props.compact !== true && format(props.date, dateFormat)}
				{props.compact === true && (
					<>
						{format(props.date, 'dd.')}
						<br />
						{format(props.date, 'MM.')}
					</>
				)}
			</Typography>
			<div className={classes.milestonesRow}>
				{milestones.map((m) => (
					<MilestoneLabel milestone={m} key={m.id} />
				))}
			</div>
		</div>
	);
}

const useStyles = makeStyles()((theme) => ({
	dateHeader: {
		flexShrink: 0,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.palette.grey[200],
		boxSizing: 'border-box',
		textAlign: 'center',
	},
	dateLabel: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		lineHeight: 1.2,
	},
	weekend: {
		backgroundColor: theme.palette.grey[300],
	},
	current: {
		backgroundColor: theme.palette.secondary.light,
	},
	milestonesRow: {
		textAlign: 'left',
		paddingLeft: theme.spacing(),
		paddingRight: theme.spacing(),
		paddingBottom: theme.spacing(0.5),
	},
}));
