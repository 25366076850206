import {createTheme} from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';

const primary = '#131b2e';
const secondary = '#009fba';
const tertiary = '#b4c8b3';
const error = '#d32f2f';
const white = '#ffffff';
const success = '#4bb543';

const Theme = {
	spacing: (multiplier = 1) => 8 * multiplier,
	white,
	primary,
	secondary,
	tertiary,
	error,
	success,
	mainRowHeight: 200,
	mainColWidth: 150,
	compactColWidth: 30,
};
export default Theme;

declare module '@mui/material/styles' {
	interface Palette {
		tertiary: Palette['primary'];
	}

	interface PaletteOptions {
		tertiary: PaletteOptions['primary'];
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
		tertiary: true;
	}
}

declare module '@mui/material/IconButton' {
	interface IconButtonPropsColorOverrides {
		tertiary: true;
	}
}

export const muiTheme = createTheme({
	typography: {
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		h1: {
			fontSize: '50px',
			fontWeight: 'bold',
		},
		h2: {
			fontSize: '30px',
		},
		h3: {
			fontSize: '25px',
		},
		h4: {
			fontSize: '20px',
		},
		h5: {
			fontSize: '17px',
		},
	},
	palette: {
		primary: {
			main: primary,
		},
		secondary: {
			main: secondary,
		},
		tertiary: {
			main: tertiary,
		},
		error: {
			main: error,
		},
	},
});
