import {colorForProject} from '@Common/colorHash';

import {fetchGeneric} from './generic';

export class SingleReport {
	planned_time: number = 0;
	estimated_time: number = 0;
	time_entries: Report[] = [];

	constructor(jsonData: any = {}) {
		Object.assign(this, jsonData);

		const time_entries = [];
		for (const entry of this.time_entries) {
			time_entries.push(new Report(entry));
		}
		this.time_entries = time_entries;
	}
}

export class Report {
	// Time Entry
	id: string = '';
	project_id: string = '';
	project_name: string = '';
	project_color: string;

	work_package_id: string = '';
	work_package_subject: string = '';

	user_id: string = '';
	user_firstname: string = '';
	user_lastname: string = '';

	activity_name: string = '';
	comments: string = '';

	hours: number = 0;
	spent_on: string = '';

	constructor(jsonData: any = {}) {
		Object.assign(this, jsonData);

		this.project_color = colorForProject(this.project_id);
	}
}

export async function fetchReport(ticketId: string) {
	const response = await fetchGeneric<SingleReport>(
		`/reports/${ticketId}`,
		SingleReport
	);
	return response;
}
